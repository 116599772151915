/**
 * Defines the scrollbar style.
 * Set the $themeName variable to 'dark' for a dark scrollbar.
 */
@mixin scrollbar($themeName) {
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    @if $themeName == "dark" {
      background: #424242;
    } @else {
      background: whitesmoke;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    @if $themeName == "dark" {
      background: #676767;
    } @else {
      background: #c0c0c0;
    }
  }

  /* Firefox Browsers */
  @-moz-document url-prefix() {
    & {
      scrollbar-width: thin;
      @if $themeName == "dark" {
        scrollbar-color: #676767 #424242;
      } @else {
        scrollbar-color: #c0c0c0 whitesmoke;
      }
    }
  }
}
