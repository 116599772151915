/****************************************************************************
* This style sheet is used by the TutorialComponent to explain users the UI *
*****************************************************************************/

.hole {
  box-shadow: 0 0 0 868px rgba(0, 0, 0, 0.5) !important;
  @extend .hole-transition;
}

.hole-transition {
  outline: 5px solid white;
  border-radius: var(--rounded-border-medium);
  transition: box-shadow .3s ease-in-out,
  outline .3s ease-in-out,
  z-index .3s ease-in-out !important;
}

@media screen and (min-width: 768px) {
  .hole {
    box-shadow: 0 0 0 1300px rgba(0, 0, 0, 0.5) !important;
  }
}

@media screen and (min-width: 1200px) {
  .hole {
    box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5) !important;
  }
}
