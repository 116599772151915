.lines-count {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.l-count-1 {
  @extend .lines-count;
  -webkit-line-clamp: 1;
}

.l-count-2 {
  @extend .lines-count;
  -webkit-line-clamp: 2;
}

.l-count-3 {
  @extend .lines-count;
  -webkit-line-clamp: 3;
}

.l-count-4 {
  @extend .lines-count;
  -webkit-line-clamp: 4;
}

.l-count-5 {
  @extend .lines-count;
  -webkit-line-clamp: 5;
}

.l-count-8 {
  @extend .lines-count;
  -webkit-line-clamp: 8;
}
