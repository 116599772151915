$videoPlayerZ: 1001;
$videoBarZ: 1000;

//$navbarHeight: 68px;
$font: 'Open Sans', sans-serif;
$carouselSelectedColor: rgb(60, 60, 60);

:root,
:host ::ng-deep {
  --navbar-height: 3.7rem;
  --body-height: calc(100dvh - var(--navbar-height));

  --cropper-outline-color: rgba(255, 255, 255, 0.5);
  --cropper-overlay-color: rgba(255, 255, 255, 0);
  --rounded-border-small: 8px;
  --rounded-border-medium: 16px;
  --rounded-border-large: 24px;
  --base-font-size: 16px;

  --primary: var(--primary-10);
  --secondary: #6676b0;
  --field-background: #f1f1f3;
  --background-highlight: #f3f5fe;
  --background-50: #edf1f7;
  --placeholder-color: var(--primary-98);
  --content-delimiter-color: var(--secondary-90);
}

@media screen and (min-width: 576px) {
  :root,
  :host ::ng-deep {
    --navbar-height: 4.25rem;
    --body-height: calc(100dvh - var(--navbar-height));
  }
}
