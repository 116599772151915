@import "vars";
@import "scss/tutorial";
@import "scss/scrollbar";
@import "scss/detached-styles";
@import "scss/lines-count";
@import "scss/single-property-classes";
@import "../node_modules/primeicons/primeicons";

/* You can add global styles to this file, and also import other style files */
:root {
  --cropper-outline-color: rgba(255, 255, 255, 0.5);
  --cropper-overlay-color: rgba(255, 255, 255, 0);
  --rounded-border-small: 8px;
  --rounded-border-medium: 16px;
  --rounded-border-large: 24px;
  --base-font-size: 16px;

  --primary-color-offer: var(--secondary-95);
  --secondary-color-offer: var(--primary-95);

  --primary-color-hint: #eef3ff;

  --primary: #0f1c41;
  --secondary: #6676b0;
  --field-background: #f1f1f3;
  --background-highlight: #f3f5fe;
  --background-50: #edf1f7;

  --text-color-success: #005300;
  --text-color-success-light: #e5ffe5;

  --warning: #e5740b;
  --text-color-invalid: #c32424;
  --text-color-invalid-light: #ffe7e7;
  --text-color-invalid-dark: #530000;
  --box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  --h1-font-size: 2rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.2rem;
  --h4-font-size: 1rem;
}

:host, :root, * {
  font-family: $font;
  font-size: var(--base-font-size);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.6;
}

body, html {
  margin: 0;
  height: 100%;
  // disables the refresh function on small devices when a user scrolled over the top boundary.
  //overscroll-behavior: contain;
  color: var(--text-color);
  scroll-behavior: smooth;
}

.container {
  display: flex;
  flex-direction: row;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, {
  line-height: 1.2;
  display: block;
}

/* Text */

h1, .h1 {
  font-size: var(--h1-font-size);
}

h2, .h2 {
  font-size: var(--h2-font-size);
}

h3, .h3 {
  font-size: var(--h3-font-size);
}

h4, .h4 {
  font-size: var(--h4-font-size);
}

h6 {
  font-style: italic;
}

.uppercase-title {
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: bold;
  letter-spacing: .3px;
}

p {
  margin: 0;
}

small {
  font-size: 0.875rem; // 14px from 16px
}

.text-small {
  font-size: 0.875rem !important;
}

.text-x-small {
  font-size: 0.75rem !important; // 12px from 16x
}

/* Hyperlink */

a {
  cursor: pointer;
  color: unset;
  text-decoration: none;
  transition: opacity .2s ease-in-out;

  &:hover {
    opacity: .9;
    transition: opacity .2s ease-in-out;
  }
}

.text-link {
  color: var(--secondary);
  font-weight: bold;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
}

/* Input */

input {
  font-size: 0.875rem;
  //padding: 8px;
  //border: 1px solid #BDBDBD;
}

label {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: .5rem;
  display: block;
}

.field-description {
  font-size: 0.9rem;
  color: var(--secondary);
  font-style: italic;
  margin: 0;
  display: flex;
  align-items: center;

  i {
    font-size: 0.9rem;
  }
}

.status-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--rounded-border-medium);
  padding: 1rem;
}

.success-box {
  @extend .status-box;
  color: var(--text-color-success) !important;
  background-color: var(--text-color-success-light) !important;
}

.failed-box {
  @extend .status-box;
  color: var(--text-color-invalid-dark) !important;
  background-color: var(--text-color-invalid-light) !important;
}

.warning-box {
  @extend .status-box;
  color: #a85100 !important;
  background-color: #ffe4d3 !important;
}

.p-invalid {
  color: red;
  display: block;
  margin: 0;
  font-size: 0.9rem;
  padding: 0;
}

.p-success {
  color: #005300;
  display: block;
  margin: 0;
  font-size: 0.9rem;
  padding: 0;
}

.p-help {
  padding-top: 5px;
  color: #BDBDBD;
  font-style: italic;
}

.p-grid {
  margin: 0 !important;
}

p-inputSwitch {
  display: flex;
}

.p-inputgroup {
  margin: 0;
}

.fit-image {
  object-fit: none;
  object-position: center;
  background-position: 50% 50%;
  background-size: cover;
}

/**
* <span class="middle-dot">&#8226;</span>
*/
.middle-dot {
  color: var(--chat-window-nickname-color);
  margin-left: 5px;
  margin-right: 5px;
}

.middle-dot-white {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
}

.box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.box-shadow-large {
  box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
}

.hide-scrollbar {
  overflow-x: scroll;
  position: relative;
  transition: all .2s ease-in-out;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, .2) transparent;

  &:hover {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    bottom: 0;
    height: 0;
    //position: absolute;
  }
}

.component-wrapper {
  width: 100%;
  max-width: 75rem;
}

.user-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.character-id {
  font-weight: bold;
  font-size: 1rem;
}


.live {
  -webkit-box-shadow: 0 0 9px 1px red;
  -moz-box-shadow: 0 0 9px 1px red;
  box-shadow: 0 0 9px 1px red;
}

.rounded-small {
  border-radius: var(--rounded-border-small) !important;
}

.rounded-medium {
  border-radius: var(--rounded-border-medium) !important;
}

.rounded-large {
  border-radius: var(--rounded-border-large) !important;
}

.no-content {
  @extend .rounded-medium;
  background-color: var(--secondary-98);
  color: var(--secondary-40);
  font-style: italic;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;

  i {
    font-size: 1rem;
    margin-right: 1rem;
  }
}

.line-height {
  line-height: 1.6;
}

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
  -webkit-transition: all .6s ease;
  /* Fade to color for Chrome and Safari */
  -webkit-backface-visibility: hidden;
}

[contenteditable] {
  outline: 0 solid transparent;
}


html, body, textarea, .p-dialog-content {
  @include scrollbar("light");
}

.badge {
  display: flex;
  align-items: center;
  padding: .2rem 1rem;
  border-radius: 16px;

  & * {
    font-size: .9rem;
    color: white;
  }

  i {
    margin-right: .5rem;
  }
}

.badge-green {
  @extend .badge;
  background-color: green;
}

.badge-blue {
  @extend .badge;
  background-color: var(--primary-40);
}

.badge-red {
  @extend .badge;
  background-color: #cb0000;
}

.badge-orange {
  @extend .badge;
  background-color: orange;
}

.key-shortcut {
  font-size: .8rem;
  padding: .05rem .3rem;
  font-weight: bold;
  color: white;
  border-radius: var(--rounded-border-small);
  background-color: var(--primary-40);
}

.p-orderlist-item.cdk-drag-preview {
  z-index: 20000 !important;
  box-shadow: unset !important;
  background: unset !important;
  padding: 0 !important;

  .profile-schedule-edit-component {
    .p-inputtext {
      background: #3b3b3b;
      color: white;
      border: none;
    }

    .p-calendar {
      width: 100%;
    }
  }
}

/* Used by the disable.directive.ts to visually disable elements */
.disabled-element {
  opacity: .6;
  cursor: default;
  user-select: none;
}

.unselectable {
  user-select: none !important;
}

.p-skeleton {
  &:not(.p-skeleton-circle) {
    border-radius: var(--rounded-border-medium);
  }
}

.required-icon {
  font-size: .5rem;
  color: #c50e4b;
}

*::selection {
  color: white;
  background-color: var(--primary);
}

.social-auth-button {
  width: 100% !important;
  background: white !important;
  border: 1px solid white !important;
  color: black !important;
}

.p-sidebar-bottom {
  height: fit-content !important;
  background-color: var(--secondary-20);
  color: var(--secondary-70);
}

.p-button-icon-only-smaller {
  height: 2rem !important;
  width: 2rem !important;
}
