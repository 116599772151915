/* =============================================
*   This file is used for styling components
*   that are attached directly to body.
*   (E.g.: Overlays, Draggable items, etc.)
* =====================================/

/**********************************
* Chat Window Context menu styles *
**********************************/
.chat-window-contextmenu {
  background-color: var(--chat-dark-color) !important; // var(--chat-dark-color)

  &.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background-color: var(--chat-light-color) !important; // var(--chat-light-color)
  }
}

.chat-window-contextmenu-danger * {
  color: red !important;
}

.chat-window-contextmenu-normal * {
  color: gray !important;
}


/******************************************
* Chat Channel Search OverlayPanel styles *
******************************************/
.chat-channel-search-overlayPanel {
  &.p-overlaypanel:after, &.p-overlaypanel:before {
    border-bottom-color: var(--chat-dark-color) !important;
  }

  .p-overlaypanel-content {
    background-color: var(--chat-dark-color) !important; // var(--chat-dark-color)
  }

  .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border-color: gray !important;
  }
}


/*************************
* Post OrderPanel styles *
*************************/
.post-order-item {
  .img-preview {
    width: 10rem;
    height: 10rem;
    @extend .fit-image;
  }

  .video-preview {
    @extend .img-preview;
    object-fit: cover !important;
  }

  .sort-icon {
    font-size: 1.5rem;
  }
}


/******************************************
* Search - LazyList OverlayPanel styles *
******************************************/
.search-lazy-list-overlay {
  min-width: 30rem;

  .p-scroller {
    @include scrollbar('light');
  }
}

/*********************
* Splash screen body *
**********************/
.splash-body {
  background-color: var(--secondary-20);
  overflow: hidden;
}

.splash-overflow {
  overflow: hidden;
  height: 100dvh !important;
}


/****************************************************
* Stripe - Bank Payment Instructions native dialog  *
*****************************************************/
iframe[src*="display_bank_transfer_instructions"] {
  display: none;
}

div:has(> iframe[src*="display_bank_transfer_instructions"]) {
  height: 0;
}

/*******************************************
* Embed Component - styles of modal dialog *
********************************************/
.embed-component-dialog-styles {
  &.p-dialog {
    border-radius: var(--rounded-border-medium);
    box-shadow: none;
  }

  .p-dialog-header {
    background: transparent;
    padding: 1rem 0;
  }

  .p-dialog-content {
    border-radius: var(--rounded-border-medium) !important;
    max-width: 80dvw;
    padding: 0;
    width: 100%;
    background: transparent;
  }

  .p-dialog-header-icons {
    display: none;
  }

  .close-button {
    &.p-button.p-button-secondary.p-button-outlined {
      border-color: white !important;
      background: rgba(0, 0, 0, 0.6);
    }

    * {
      color: white;
    }
  }

  .p-skeleton {
    background-color: black !important;
  }

  .p-skeleton:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)) !important;
  }
}
