/************
* Positions *
*************/
.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

/**********
* Cursors *
***********/

.c-pointer {
  cursor: pointer;
  user-select: none;
}

.c-default {
  cursor: default;
}

.c-move {
  cursor: move;
}

.c-ns-resize {
  cursor: ns-resize;
}

/*****************
* Width & Height *
******************/

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

/*****************
* Word break *
******************/
.word-break {
  word-break: break-all;
}

/*****************
*     Others     *
******************/
.line-through {
  text-decoration: line-through;
}

.p-text-underline {
  text-decoration: underline;
}

.bg-white {
  background-color: #fff !important;
}
